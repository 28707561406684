import { createStore } from 'vuex'
import config from '../config.json'
import auth from './auth'


export default createStore({
    modules: {
        auth
    },
    state: {
        authUser: {},
        isAuthenticated: false,
        jwt: localStorage.getItem('token'),
        endpoints: {
            obtainJWT: config.api_urls.base_url + config.api_urls.obtain_token,
            refreshJWT: config.api_urls.base_url + config.api_urls.refresh_token,
            baseUrl: config.api_urls.base_url
        }
    },
    mutations: {

    },
    actions: {
    },
    getters: {

    }
})
