<template>
    <div class="main-page has-text-left">
        <div class="is-flex mt-6 is-align-items-center">
            <h1 class="title m-0">{{ topicData.topic.title }}</h1>
            <span class="ml-3"><strong class="has-text-success">{{ topicData.topic.date}}</strong></span>
        </div>
        <div class="mt-5">
            <div class="columns is-desktop">
                <div v-if="!isEditing && !isTextEditing" class="column is-four-fifths">
                    <div class="mb-5">
                        <button class="tag button is-small mr-1">Распечатать</button>
                        <button class="tag button is-small mr-1">На суфлер</button>
                        <button v-on:click="setEditMode()" class="tag button is-small mr-1">Редактировать</button>
                        <button v-on:click="setTextEditMode()" class="tag button is-small mr-1">Редактировать текст
                        </button>
                        <button class="tag button is-small mr-1">В архив</button>
                        <button class="tag button is-small mr-1">Выгнать в файл</button>
                        <button class="tag button is-danger is-small mr-1">Удалить</button>
                        <button class="tag button is-small mr-1">Добавить в выпуск</button>
                        <button class="tag button is-small mr-1">Выложить на сайт</button>
                    </div>
                   <div class="small-text-13 mb-2 has-background-grey-light"><span class="">Подводка</span><span class="timer ml-3">{{ topicData.topic.length_anons }}</span>
                    </div>

                    <div class="text-block mb-4">{{ topicData.topic.anons }}</div>
                    <div class="small-text-13 mb-2 has-background-warning"><span>Сюжет: </span><span class="timer ml-3">{{ topicData.topic.length_content }}</span>
                    </div>

                  <div class="flex m-10">
                    <draggable :list="topicData.content" @change="setOrder(1)">
                      <div class="text-block mb-4" v-for="element in topicData.content" :key="element.type">
                          <span style="font-weight: bold">{{element.duration}} - [{{element.type}}]</span>
                          <button type="button" class="cards__edit" @click="setContentText(element.text,element.id)"></button>
                          <button type="button" class="cards__apply" :style="setOpacity(element.id)"
                                  @click="setCardText(element.id)"></button>
                          <button type="button" class="cards__cancel" :style="setOpacity(element.id)"
                            @click="cancelEdit()"></button>
                          <p>topicCard_id: {{element.id}}</p>
                          <div v-if="cardToEdit.id === element.id">
                            <input v-model="cardToEdit.text">
                          </div>
                          <div v-else><p>{{ element.text }}</p></div>

                      </div>
                    </draggable>
                  </div>

<!--                  Выводим данные по сюжету из легаси логики-->
                    <div v-if="topicData.topic.content" class="text-block mb-4">
                        <div v-html="topicData.topic.content"></div>
                    </div>

                    <div class="small-text-13 mb-2 has-background-danger-light">
                        <span>Отводка: </span><span class="timer ml-3">{{ topicData.topic.length_final }}</span></div>
                    <div class="text-block mb-4">{{ topicData.topic.final }}</div>
                </div>

                <div v-if="isEditing" class="column is-four-fifths">
                    <form class="form" action="">
                        <div class="field is-grouped">
                            <p class="control is-expanded">
                                <input class="input is-small mr-2" type="text" placeholder="Заголовок:">
                            </p>
                            <p class="control is-expanded">
                                <input class="input is-small mr-2" type="date">
                            </p>
                            <p class="control">
                                <label for="always_active" class="has-text-left small-text-13">
                                    Всегда активный
                                </label>
                                <input id="always_active" type="checkbox" class="ml-1"></p>
                        </div>
                        <div class="field is-grouped">
                            <div class="control is-expanded is-flex is-align-items-center flex-grow-0">
                                <label for="workplace_list" class="label has-text-left small-text-13 m-0 mr-2">Рабочее
                                    место</label>
                                <div class="select is-small">
                                    <select id="workplace_list" class="is-full">
                                        <option>
                                            Рабочее место
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="control is-expanded is-flex is-align-items-center">
                                <label for="type_list" class="label has-text-left small-text-13 m-0 mr-2">Тип</label>
                                <div class="select is-small is-fullwidth">
                                    <select id="type_list" class="is-full">
                                        <option>
                                            Тип
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="control is-expanded is-flex is-align-items-center">
                                <label for="status_list" class="label has-text-left small-text-13 m-0 mr-2">Статус</label>
                                <div class="select is-small is-fullwidth">
                                    <select id="status_list" class="is-full">
                                        <option value="writing">Пишется</option>
                                        <option value="checking">На проверке</option>
                                        <option value="processing">В производстве</option>
                                        <option value="air">В эфире</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div class="field is-grouped">
                            <div class="control is-expanded is-flex is-align-items-center">
                                <label for="journalist_list" class="label has-text-left small-text-13 m-0 mr-2">Журналист</label>
                                <div class="select is-small is-fullwidth">
                                    <select id="journalist_list" class="is-full">
                                        <option>Ангелина Костюкова</option>
                                        <option>Антонов Иговрь</option>
                                        <option>Виноградова Виктория</option>
                                        <option>Владислав Нам</option>
                                        <option>Ворона Екатерина</option>
                                    </select>
                                </div>
                            </div>
                            <div class="control is-expanded is-flex is-align-items-center">
                                <label for="soundman_list" class="label has-text-left small-text-13 m-0 mr-2">Озвучка</label>
                                <div class="select is-small is-fullwidth">
                                    <select id="soundman_list" class="is-full">
                                        <option>Ангелина Костюкова</option>
                                        <option>Антонов Иговрь</option>
                                        <option>Виноградова Виктория</option>
                                        <option>Владислав Нам</option>
                                        <option>Ворона Екатерина</option>
                                        <option selected>Зайцева Наталья</option>
                                    </select>
                                </div>
                            </div>
                            <div class="control is-expanded is-flex is-align-items-center">
                                <label for="cutter_list" class="label has-text-left small-text-13 m-0 mr-2">Монтажер</label>
                                <div class="select is-small is-fullwidth">
                                    <select id="cutter_list" class="is-full">
                                        <option>Шапошников Лева</option>
                                        <option>Титоренко Дима</option>
                                        <option>Дмитрий Малашкин</option>
                                        <option>Шустиков Сергей</option>
                                        <option>Константин Лисицын</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div class="field is-grouped">
                            <p class="control">
                                <span class="label has-text-left small-text-13">Операторы</span>
                            </p>
                            <p class="control">
                                <label class="checkbox is-block has-text-left small-text-13">
                                    <input type="checkbox">Оператор
                                </label>
                            </p>
                        </div>
                        <button className="button is-danger is-active mt-2">Отправить</button>

                    </form>
                </div>
                <div v-if="isTextEditing" class="column is-four-fifths">
                    <div class="small-text-13 mb-2"><span class="">Подводка</span></div>
                    <div class="field">
                        <div class="control">
                            <textarea class="textarea is-small"></textarea>
                        </div>
                    </div>

                    <div class="small-text-13 mb-2"><span class="">Текст сюжета</span></div>
                    <div class="mb-2">
                        <button class="tag button is-small mr-1">Синхрон</button>
                        <button class="tag button is-small mr-1">Stand-UP</button>
                        <button class="tag button is-small mr-1">Life</button>
                        <button class="tag button is-small mr-1">Интершум</button>
                        <button class="tag button is-small mr-1">Замечание</button>
                    </div>

                    <div class="field">
                        <div class="control">
                            <textarea class="textarea is-small textarea-big"></textarea>
                        </div>
                    </div>
                    <div class="small-text-13 mb-2"><span class="">Отводка</span></div>
                    <div class="field">
                        <div class="control">
                            <textarea class="textarea is-small"></textarea>
                        </div>
                    </div>
                    <div class="is-flex mb-3">
                        <label class="checkbox mr-5 small-text-13">
                            <input type="checkbox">
                            Отправить на доделку
                        </label>
                        <label class="checkbox small-text-13">
                            <input type="checkbox">
                            Отправить в производство
                        </label>
                    </div>
                    <button className="button is-danger is-active mt-2">OK</button>

                </div>

                <div class="column">
                    <div class="user-info-block small-text-13">
                        <div class="user-info-block-item" v-for="c in topicStaff.journalist" v-bind:key="c.user_id"><span>Журналист</span><span>{{c.user}}</span></div>
                        <div class="user-info-block-item" v-for="c in topicStaff.soundman" v-bind:key="c.user_id"><span>Озвучка:	</span><span>{{c.user}}</span></div>
                        <div class="user-info-block-item" v-for="c in topicStaff.cutter" v-bind:key="c.user_id"><span>Монтажер:	</span><span>{{c.user}}</span></div>
                        <div class="user-info-block-item" v-for="c in topicStaff.cameraman" v-bind:key="c.user_id"><span >Оператор:</span><span>{{ c.user }}</span></div>
                        <div class="user-info-block-item"><span>Длина (общая):	</span><span>{{ topicData.topic.length_all }}</span></div>
                        <div class="user-info-block-item"><span>Длина (файла):	</span><span>{{ topicData.topic.length_content }}</span></div>
                        <div class="user-info-block-item">
                            <span>Статус</span>
                              <span class="tag is-warning" v-if="topicData.topic.state === 'writing'">Пишется</span>
                              <span class="tag is-warning" v-else-if="topicData.topic.state === 'checking'">На проверке</span>
                              <span class="tag is-warning" v-else-if="topicData.topic.state === 'processing'">В производстве</span>
                              <span class="tag is-warning" v-else-if="topicData.topic.state === 'air'">В эфире</span>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>

</template>

<script>
    import {getTopicInfo,updateTopic} from "@/api/topic";
    import { VueDraggableNext } from 'vue-draggable-next'
    export default {
      components: {
        draggable: VueDraggableNext,
      },
        name: "Topic",
        data() {
            return {
                enabled: true,
                dragging: false,
                formatedTextContent: '',
                isEditing: false,
                isTextEditing: false,
                topicData: {topic: ''},
                receivedStaff: {},
                topicStaff: {
                  cameraman: [],
                  soundman: [],
                  journalist: [],
                  cutter: []
                },
                cardToEdit: {
                  text: '',
                  id: '',
                  isEdit: false
                },
                style: {
                  opacity: 'opacity: 20%'
                }
            }
        },
        methods: {
            setEditMode() {
                this.isEditing = !this.isEditing;
            },
            setTextEditMode() {
                this.isTextEditing = !this.isTextEditing;
            },
            setOrder(isOrder) {
              this.topicData.content.forEach(function (item,index) {
                item.order = index;
              });
              if (isOrder === 1) {
                updateTopic(this.topicData)
              }
            },
            setContentText(event,id) {
              this.cardToEdit.text = event;
              this.cardToEdit.id = id;
              this.style.opacity = 'opacity: 100%'
            },
            setCardText(cardId) {
              let self = this;
              this.topicData.content.forEach(function (item) {
                if (item.id === cardId && self.cardToEdit.id === item.id) {
                  item.text = self.cardToEdit.text;
                  self.cardToEdit.id = null;
                  self.style.opacity = 'opacity: 20%';
                  updateTopic(self.topicData);
                }
              });
            },
            cancelEdit() {
              this.cardToEdit.id = null;
              this.style.opacity = 'opacity: 20%'
            },
            setOpacity(id) {
              if (id === this.cardToEdit.id) {
                return 'opacity: 100%';
              }
              else {
                return 'opacity: 20%';
              }
            }
        },
      beforeMount() {
        getTopicInfo(this.$route.params.id).then(response => {
          this.topicData = response.data;
          this.receivedStaff = response.data.staff;

          let cameraman = [];
          let soundman = [];
          let journalist = [];
          let cutter = [];

          // Здесь мы подкладываем список пользователей взависимости от роли для отображения в списках

          response.data.staff.forEach(function (item) {
            if (item.role === 'cameraman') {
              cameraman.push(item);
            }
            else if (item.role === 'soundman')
              soundman.push(item);
            else if (item.role === 'journalist')
              journalist.push(item);
            else if (item.role === 'cutter')
              cutter.push(item);
          });

          this.topicStaff.cameraman = cameraman;
          this.topicStaff.soundman = soundman;
          this.topicStaff.journalist = journalist;
          this.topicStaff.cutter = cutter;
          this.setOrder(0);
        });
      }
    }
</script>


<style scoped>
  .timer {
    color: green;
    font-weight: bold;
  }
</style>
