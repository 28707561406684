<template>
    <div class="main-page">
        <div class="is-flex is-align-items-center">
            <h1 class="title m-0">Новый выпуск</h1>
        </div>
        <div class="mt-5">
            <form class="form" action="">
                <input class="input is-small" type="text" placeholder="Название выпуска:">
                <input class="input is-small mt-1" type="date">
                <button class="button is-danger is-active mt-2">Отправить</button>
            </form>
        </div>
    </div>

</template>

<script>
export default {
  name: "AddIssue"
}
</script>

<style scoped>

</style>
