<template>
    <div class="login login--indent">
        <img class="login__logo" src="../assets/images/logo.svg" width="198" height="53" alt="Телемикс">
        <h2 class="login__title">Войти в аккаунт</h2>
        <p class="login__description">Получите доступ к внутреннему порталу, используя свой
            логин и пароль.</p>
        <form class="login__form" @submit.prevent="submit">
            <label class="login__label" for="login">Логин</label>
            <input class="form__text-input login__input" id="login" required v-model="inputs.username" type="text" placeholder="Введите логин"/>
            <label class="login__label" for="pass">Пароль</label>
            <div class="login__pass-wrapper">
                <a type="password" tabindex="-1" class="login__view-pass" @click="switchVisibility" href=""></a>
                <input class="form__text-input login__input" id="pass" required v-model="inputs.password" :type="passwordFieldType" placeholder="Введите пароль"/>
            </div>

            <button class="button" @click="login(inputs)" id="login-button">Войти</button>
        </form>
    </div>

</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                inputs: {
                    username: '',
                    password: ''
                },
                passwordFieldType: 'password'
            }
        },
        methods: {
            login({ username, password }) {
                this.$store.dispatch('auth/login', { username, password })
                    .then(() => this.$router.push('/cabinet'));
            },
            switchVisibility(event) {
                event.preventDefault();
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
            }
        },
    }
</script>

<style scoped>

</style>
