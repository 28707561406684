import { createRouter, createWebHistory } from 'vue-router'
import Topics from "@/views/Topics";
import Issues from "@/views/Issues";
import Cabinet from "@/views/Cabinet";
import AddTopic from "@/views/Add-Topic";
import AddIssue from "@/views/Add-Issue";
import Topic from "@/views/Topic";
import Issue from "@/views/Issue";
import Login from "@/views/Login";
import store from '../store'


const requireAuthenticated = (to, from, next) => {
  store.dispatch('auth/initialize')
      .then(() => {
        if (!store.getters['auth/isAuthenticated']) {
          next('/login');
        } else {
          next();
        }
      });
};

const requireUnauthenticated = (to, from, next) => {
  store.dispatch('auth/initialize')
      .then(() => {
        if (store.getters['auth/isAuthenticated']) {
          next('/cabinet');
        } else {
          next();
        }
      });
};
//
// const redirectLogout = (to, from, next) => {
//   store.dispatch('auth/logout')
//       .then(() => next('/login'));
// };

const routes = [
  {
    path: '/',
    redirect: '/cabinet',
    beforeEnter: requireAuthenticated
  },  
  {
    path: '/topics',
    name: 'topics',
    component: Topics,
    beforeEnter: requireAuthenticated
  },
  {
    path: '/issues',
    name: 'issues',
    component: Issues,
    beforeEnter: requireAuthenticated
  },  
  {
    path: '/cabinet',
    name: 'cabinet',
    component: Cabinet,
    beforeEnter: requireAuthenticated
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: requireUnauthenticated
  },
  {
    path: '/add-topic',
    name: 'add-topic',
    component: AddTopic,
    beforeEnter: requireAuthenticated
  },
  {
    path: '/add-issue',
    name: 'add-issue',
    component: AddIssue
  },
  {
    path: '/topics/:id',
    name: 'topicId',
    component: Topic,
    props: true,
    beforeEnter: requireAuthenticated
  },
  {
    path: '/issues/:id',
    name: 'issueId',
    component: Issue,
    props: true,
    beforeEnter: requireAuthenticated
  }  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
