import _ from "lodash";

export function pagination(data) {
    let allPages;
    let currentPage;
    let lastPage;
    allPages = _.range(1, data.last_page + 1);
    currentPage = data.current_page;
    lastPage = data.last_page;
    return {
        allPages,
        currentPage,
        lastPage
    };
}