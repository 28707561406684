<template>
    <div class="main-page">
        <div class="is-flex is-align-items-center">
            <h1 class="title m-0">Новый сюжет</h1>
        </div>
        <div class="mt-5 is-flex is-justify-content-center">
            <form class="form" action="" @submit.prevent="createTopicMethod(topicCreateData)">
                <div class="field is-grouped">
                    <p class="control is-expanded">
                        <input class="input is-small mr-2" type="text" placeholder="Заголовок:" v-model="topicCreateData.topic.title">
                    </p>
                    <p class="control is-expanded">
                        <input class="input is-small mr-2" type="date" v-model="topicCreateData.topic.date">
                    </p>
                    <p class="control">
                        <label for="always_active" class="has-text-left small-text-13">
                            Всегда активный
                        </label>
                        <input id="always_active" type="checkbox" class="ml-1"
                               v-on:change="setActive"></p>
                </div>
                <div class="field is-grouped">
                    <div class="control is-expanded is-flex is-align-items-center flex-grow-0">
                        <label for="workplace_list" class="label has-text-left small-text-13 m-0 mr-2">Рабочее
                            место</label>
                        <div class="select is-small">
                            <select id="workplace_list" class="is-full" v-model="topicCreateData.topic.workspace_id"
                                    v-on:click="getTypes">
                                <option v-for="w in workspaceData" v-bind:key="w.id" v-bind:value="w.id">
                                    {{w.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="control is-expanded is-flex is-align-items-center">
                        <label for="type_list" class="label has-text-left small-text-13 m-0 mr-2">Тип</label>
                        <div class="select is-small is-fullwidth">
                            <select id="type_list" class="is-full" v-model="topicCreateData.topic.type_id_id">
                                <option v-for="t in topicTypesData" v-bind:key="t.id" v-bind:value="t.id">
                                    {{t.title}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="control is-expanded is-flex is-align-items-center">
                        <label for="status_list" class="label has-text-left small-text-13 m-0 mr-2">Статус</label>
                        <div class="select is-small is-fullwidth">
                            <select id="status_list" class="is-full" v-model="topicCreateData.topic.state">
                                <option value="writing">Пишется</option>
                                <option value="checking">На проверке</option>
                                <option value="processing">В производстве</option>
                                <option value="air">В эфире</option>
                            </select>
                        </div>
                    </div>

                </div>
                <div class="field is-grouped">
                    <div class="control is-expanded is-flex is-align-items-center">
                        <label for="journalist_list" class="label has-text-left small-text-13 m-0 mr-2">Журналист</label>
                        <div class="select is-small is-fullwidth">
                            <select id="journalist_list" class="is-full" v-model="topicCreateData.stuff[1].user_id">
                                <option v-for="j in journalistList" v-bind:key="j.role_id"
                                        v-bind:value="j.user_id">{{j.user_full_name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="control is-expanded is-flex is-align-items-center">
                        <label for="soundman_list" class="label has-text-left small-text-13 m-0 mr-2">Озвучка</label>
                        <div class="select is-small is-fullwidth">
                            <select id="soundman_list" class="is-full" v-model="topicCreateData.stuff[0].user_id">
                                <option v-for="s in soundmanList" v-bind:key="s.role_id"
                                        v-bind:value="s.user_id">
                                  {{s.user_full_name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="control is-expanded is-flex is-align-items-center">
                        <label for="cutter_list" class="label has-text-left small-text-13 m-0 mr-2">Монтажер</label>
                        <div class="select is-small is-fullwidth">
                            <select id="cutter_list" class="is-full" v-model="topicCreateData.stuff[2].user_id">
                                <option v-for="c in cutterList" v-bind:key="c.role_id"
                                        v-bind:value="c.user_id">{{c.user_full_name}}</option>
                            </select>
                        </div>
                    </div>

                </div>
                <div class="field is-grouped">
                    <p class="control">
                        <span class="label has-text-left small-text-13">Операторы</span>
                    </p>
                    <p class="control">
                      <label class="checkbox is-block has-text-left small-text-13" v-for="operator in operatorList"
                             v-bind:key="operator.user_id" v-bind:value="operator.user_id">
                        <input type="checkbox" @change="createOperatorDataList($event, operator)">{{ operator.user_full_name }}
                      </label>
                    </p>
                </div>

        <button className="button is-danger is-active mt-2">Отправить</button>


      </form>
    </div>
  </div>
</template>

<script>
import {createTopic, getTopicTypes} from "@/api/topic";
import {getUserRole} from "@/api/userRoles";
import {getWorkspaces} from "@/api/workspace";

export default {
  name: "AddTopic",
  data() {
    return {
      operatorList: [],
      soundmanList: [],
      journalistList: [],
      cutterList: [],
      workspaceData: '',
      topicTypesData: '',
      topicCreateData: {
        topic: {
          title: '',
          date: new Date().toISOString().slice(0, 10),
          created_at: new Date().toISOString(),
          type_id_id: '',
          workspace_id: '',
          state: 'writing',
          sticky: 0,
        },
        stuff: [
          {
            user_id: null,
            topic_type: 'Topic',
            name: 'soundman'
          },
          {
            user_id: null,
            topic_type: 'Topic',
            name: 'journalist'
          },
          {
            user_id: null,
            topic_type: 'Topic',
            name: 'cutter'
          }
        ]
      }
    }
  },
  methods: {
    setActive(event) {
      if (event.target.checked === true) {
        this.topicCreateData.topic.sticky = 1;
      }
      else this.topicCreateData.topic.sticky = 0;
    },
    createOperatorDataList(event, data) {
      // Функция для формирования списка объектов для операторов. Для взаимодействия с чекбоксами
      let dataEdited = {};

      this.topicCreateData.stuff.every(createObject);

      function createObject(el, index, arr) {
        if (event.target.checked === true) {
          if (el.user_id === data.user_id) {
            return false;
          } else
              dataEdited['user_id'] = data.user_id;
              dataEdited['topic_type'] = 'Topic';
              dataEdited['name'] = 'cameraman'
              arr.push(dataEdited);
        }
      }

      this.topicCreateData.stuff.forEach(function (item, index, object) {
        if (event.target.checked === false) {
          if (item.user_id === data.user_id && item.name === 'cameraman') {
            object.splice(index, 1);
          }
        }
      });
    },
    getTypes() {
      getTopicTypes(this.topicCreateData.topic.workspace_id).then(response => {
        this.topicTypesData = response.data;
        if (response.data) {
          this.topicCreateData.topic.type_id_id = response.data[0].id;
        }
      })
    },
    createTopicMethod(data) {
      createTopic(data)
    }
  },
  beforeMount() {
    getWorkspaces().then(response => {
      this.workspaceData = response.data;
      if (response.data) {
        this.topicCreateData.topic.workspace_id = response.data[0].id;
        this.getTypes();
      }
    });
    getUserRole('Озвучка').then(response => {
      this.soundmanList = response.data;
      this.topicCreateData.stuff[0].user_id = response.data[0].user_id;
    });
    getUserRole('Журналист').then(response => {
      this.journalistList = response.data;
      this.topicCreateData.stuff[1].user_id = response.data[1].user_id;
    });
    getUserRole('Монтажер').then(response => {
      this.cutterList = response.data;
      this.topicCreateData.stuff[2].user_id = response.data[2].user_id;
    });
    getUserRole('Оператор').then(response => {this.operatorList = response.data;});
  }
}
</script>

<style scoped>

</style>
