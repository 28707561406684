import session from "@/api/session";

var API_URL = process.env.VUE_APP_ENV_API_BASE_URL

export function getTopic(page, searchString) {
    if (searchString != '') {
        return session.get(API_URL + 'topic/?format=json' + '&search=' + searchString + '&page=' + page); 
        
    }
    else {
        return session.get(API_URL + 'topic/?format=json' + '&page=' + page);
    }
}

export function getTopicInfo(topic_id) {
    return session.get(API_URL + 'topic/?id=' + topic_id);
}

export function getTopicTypes(workspace) {
    return session.get(API_URL + 'topic_types/?workspace_id=' + workspace);
}

export function createTopic(data) {
    session.post(API_URL + 'topic/', data).catch(function(error){
        console.log('Error: ' + error)
    })
}

export function updateTopic(data) {

    if (data.topic.final === '') {
        console.log('Delete')
        delete data.topic['final']
    }

    if (data.topic.anons === '') {
        console.log('Delete')
        delete data.topic['anons']
    }

    session.put(API_URL + 'topic/', data).catch(function(error){
        console.log('Error: ' + error)
    })
}