<template>
  <div>
    <div class="header">
      <div class="header__bar">
        <a class="mainav__open" @click="toggle" v-bind:class="[isOpen ? closeClass : '', openClass]" href=""></a>
        <img class="header__logo" src="../assets/images/logo-inner.svg" width="170" height="48"
             alt="Телемикс - рекламный портал">
        <a class="nav__open" @click="toggleNav" href=""></a>
        <input class="ml-5 input input--custom" placeholder="Поиск" type="text">
        <div class="is-flex ml-5 is-align-items-center">
          <div class="select">
            <select v-model="selectedWorkspaceId">
              <option v-for="w in workspaceData" v-bind:key="w.id" v-bind:value="w.id">{{w.name}}</option>
            </select>
          </div>
          <span class="tag is-danger is-light has-text-weight-bold mr-5 ml-5">Пишется: 0</span>
          <span class="tag is-success is-light has-text-weight-bold mr-5">В производстве: 10</span>
          <span class="tag is-info is-light has-text-weight-bold mr-5">В эфире: 10</span>
          <span class="tag is-success has-text-weight-bold">Графики: 10</span>

        </div>
        <div class="header__user-wrapper">
          <router-link to="/profile" class="header__user"></router-link>
          <div class="header__user-info">
            <span class="header__user-post header__user-post--in-bar">Имя пользователя</span>
            <span class="header__user-post header__user-post--in-bar"></span>
<!--            <router-link to="/profile" class="header__user-name header__user-name&#45;&#45;in-bar">firstName-->
<!--              lastName-->
<!--            </router-link>-->
          </div>
          <a class="header__logout" @click="logout">
            <svg class="header__bar-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M255.15 468.625H63.787c-11.737 0-21.262-9.526-21.262-21.262V64.638c0-11.737 9.526-21.262 21.262-21.262H255.15c11.758 0 21.262-9.504 21.262-21.262S266.908.85 255.15.85H63.787C28.619.85 0 29.47 0 64.638v382.724c0 35.168 28.619 63.787 63.787 63.787H255.15c11.758 0 21.262-9.504 21.262-21.262 0-11.758-9.504-21.262-21.262-21.262z"/>
              <path d="M505.664 240.861L376.388 113.286c-8.335-8.25-21.815-8.143-30.065.213s-8.165 21.815.213 30.065l92.385 91.173H191.362c-11.758 0-21.262 9.504-21.262 21.262 0 11.758 9.504 21.263 21.262 21.263h247.559l-92.385 91.173c-8.377 8.25-8.441 21.709-.213 30.065a21.255 21.255 0 0015.139 6.336c5.401 0 10.801-2.041 14.926-6.124l129.276-127.575A21.303 21.303 0 00512 255.998c0-5.696-2.275-11.118-6.336-15.137z"/>
            </svg>
          </a>
          <div class="header__user-dropdown is-hidden">
            <div class="header__user-name-block">
              <span class="header__user-name">Василий Алибабаевич</span>
              <span class="header__user-post"></span>
            </div>
            <div class="header__user-action">
              <a class="header__user-link" href="">
                <svg class="header__user-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path class="header__user-icon" d="M437 331c-27.9-27.9-61.1-48.5-97.3-61 38.8-26.7 64.3-71.4 64.3-122C404 66.4 337.6 0 256 0S108 66.4 108 148c0 50.5 25.5 95.3 64.3 122-36.2 12.5-69.4 33.1-97.3 61C26.6 379.3 0 443.6 0 512h40c0-119.1 96.9-216 216-216s216 96.9 216 216h40c0-68.4-26.6-132.7-75-181zm-181-75c-59.6 0-108-48.4-108-108S196.4 40 256 40s108 48.4 108 108-48.4 108-108 108z" fill="#fff"/>
                </svg>
                Данные пользователя
              </a>
            </div>
            <div class="header__user-action">
              <a class="header__user-link" href="">
                <svg class="header__user-icon" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512">
                  <path d="M255.15 468.625H63.787c-11.737
                                     0-21.262-9.526-21.262-21.262V64.638c0-11.737 9.526-21.262
                                     21.262-21.262H255.15c11.758 0 21.262-9.504 21.262-21.262S266.908.85
                                     255.15.85H63.787C28.619.85 0 29.47 0 64.638v382.724c0 35.168 28.619
                                     63.787 63.787 63.787H255.15c11.758 0 21.262-9.504 21.262-21.262
                                     0-11.758-9.504-21.262-21.262-21.262z"/>
                  <path d="M505.664 240.861L376.388
                                     113.286c-8.335-8.25-21.815-8.143-30.065.213s-8.165 21.815.213 30.065l92.385
                                     91.173H191.362c-11.758 0-21.262 9.504-21.262 21.262 0 11.758 9.504 21.263
                                     21.262 21.263h247.559l-92.385 91.173c-8.377 8.25-8.441 21.709-.213
                                     30.065a21.255 21.255 0 0015.139 6.336c5.401 0 10.801-2.041
                                     14.926-6.124l129.276-127.575A21.303 21.303 0
                                     00512 255.998c0-5.696-2.275-11.118-6.336-15.137z"/>
                </svg>
                Выход
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
    <nav class="mainnav" v-bind:class="{mainnav__open: isOpen}">
      <h4 class="mainnav__title">Меню пользователя</h4>
      <ul class="mainnav__list mt-3">
        <li class="mainnav__item">
          <router-link to="" class="mainnav__link" href="">
            Обратная связь
          </router-link>
        </li>
        <li class="mainnav__item">
          <router-link to= "/add-topic" class="mainnav__link">
            Добавить сюжет
          </router-link>
        </li>
        <li class="mainnav__item">
          <router-link to="/add-issue" class="mainnav__link">
            Добавить выпуск
          </router-link>
        </li>
        <li class="mainnav__item">
          <router-link to="/topics" class="mainnav__link">
            Архив сюжетов
          </router-link>
        </li>
        <li class="mainnav__item">
          <router-link to="/" class="mainnav__link">
            Отчеты
          </router-link>
        </li>
        <li class="mainnav__item">
          <router-link to="/" class="mainnav__link">
            Архив выпусков
          </router-link>
        </li>
        <li class="mainnav__item">
          <router-link to="/" class="mainnav__link">
            Архив выпусков
          </router-link>
        </li>
        <li class="mainnav__item">
          <router-link to="/" class="mainnav__link">
            Удаленные
          </router-link>
        </li>
        <li class="mainnav__item">
          <router-link to="/" class="mainnav__link">
            Инфо. блоки
          </router-link>
        </li>
        <li class="mainnav__item">
          <router-link to="/" class="mainnav__link">
            Партнеры
          </router-link>
        </li>
        <li class="mainnav__item">
          <router-link to="/" class="mainnav__link">
            Доска
          </router-link>
        </li>
        <li class="mainnav__item">
          <router-link to="/" class="mainnav__link">
            Рабочие места
          </router-link>
        </li>
        <li class="mainnav__item">
          <router-link to="/" class="mainnav__link">
            Отчеты
          </router-link>
          <hr class="navbar-divider">
        </li>

      </ul>
    </nav>
  </div>
</template>

<script>
import {getWorkspaces} from "@/api/workspace";
export default {
  name: "Navigation",
  data() {
    return {
      isOpen: false,
      isNavOpen: false,
      openClass: 'mainnav__open',
      closeClass: 'mainav__closed',
      openMenu: 'nav__opened',
      closeMenu: 'nav__closed',
      workspaceData: '',
      selectedWorkspaceId: ''
    }
  },
  watch: {
    selectedWorkspaceId() {
      localStorage.setItem("selectedWorkspace", this.selectedWorkspaceId);
    }
  },
  mounted() {
  },
  props: {
    msg: String
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
          .then(() => this.$router.push('/login'));
    },
    toggle(e) {
      e.preventDefault();
      this.isOpen = !this.isOpen;
    },

    toggleNav(e) {
      e.preventDefault();
      this.isNavOpen = !this.isNavOpen;
    }
  },
  beforeMount() {
    if (localStorage.getItem('TOKEN_STORAGE_KEY')) {
      getWorkspaces().then(response => {
        this.workspaceData = response.data;
        if (localStorage.getItem("selectedWorkspace") === null) {
          this.selectedWorkspaceId = response.data[0].id
          localStorage.setItem("selectedWorkspace", response.data[0].id)
        }
        else
          this.selectedWorkspaceId = localStorage.getItem("selectedWorkspace");
      });
    }
  }
}
</script>

<style scoped>
  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>
