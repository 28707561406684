<template>
  <div class="main-page">
    <div class="is-flex mt-6 is-align-items-center">
      <h1 class="title m-0">Архив выпусков</h1>
      <input class="ml-5 input input--custom is-block" placeholder="Поиск" type="text" v-model="searchString" @keypress.enter="topic(page)">
    </div>
    <div>
      <nav class="pagination is-small mt-5">
        <ul class="pagination-list">
          <li class="m-0" v-if="(paginationData.currentPage >= 5) && (paginationData.currentPage <= (paginationData.lastPage - 4))">
            <a class="pagination-link" v-bind:class="{ 'is-current': isPageActive(page) }" @click.prevent="topic(1)">1</a>
            <span class="pagination-ellipsis">…</span>
            <a class="pagination-link" v-bind:class="{ 'is-current': isPageActive(page) }"
               v-for="page in paginationData.allPages.slice(paginationData.currentPage-2,paginationData.currentPage+3)"
               v-bind:key="page" @click.prevent="topic(page)">{{page}}
            </a>
            <a class="pagination-link" v-bind:class="{ 'is-current': isPageActive(page) }" @click.prevent="topic(paginationData.lastPage)">
              {{this.paginationData.lastPage}}
            </a>
          </li>

          <li class="m-0" v-else-if="(paginationData.currentPage <= 5)">
            <a class="pagination-link" v-for="page in paginationData.allPages.slice(0, 5)" v-bind:key="page" v-bind:class="{ 'is-current': isPageActive(page) }"
               @click.prevent="topic(page)">{{page}}
            </a>
            <span v-if="paginationData.lastPage > 5" class="pagination-ellipsis">…</span>
            <a v-if="paginationData.lastPage > 5" class="pagination-link" v-bind:class="{ 'is-current': isPageActive(page) }" @click.prevent="topic(paginationData.lastPage)">
              {{this.paginationData.lastPage}}
            </a>
          </li>

          <li class="m-0" v-else-if="(paginationData.currentPage - 5 || (paginationData.currentPage === paginationData.lastPage))">
            <a class="pagination-link" v-bind:class="{ 'is-current': isPageActive(page) }" @click.prevent="topic(1)">1</a>
            <span class="pagination-ellipsis">…</span>
            <a class="pagination-link" v-bind:class="{ 'is-current': isPageActive(page) }"
               v-for="page in paginationData.allPages.slice((paginationData.lastPage - 5 ), paginationData.lastPage)"
               v-bind:key="page" @click.prevent="topic(page)">{{page}}
            </a>
          </li>
        </ul>
      </nav>
      <div>
        <table class="table table is-hoverable is-fullwidth is-striped">
          <thead>
          <tr>
            <th>Заголовок</th>
            <th>Редактор</th>
            <th>Ведущий</th>
            <th>Дата</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="topic in topics" v-bind:key="topic">
            <td><a class="links" href="">{{topic.topic_title}}</a></td>
            <td>{{staffJournalist(topic.staff)}}</td>
            <td> - </td>
            <td>{{topic.date}}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
  import {getTopic} from "@/api/topic";
  import {pagination} from "@/services/paginator";
  export default {
    name: "Issues",
    data() {
      return {
        page: '1',
        searchString: '',
        paginationData: {
          allPages: '',
          currentPage: '',
          lastPage: ''
        },
        isActive: true,
        isPageCurrent: false,
        topics: '',
        testJ: ''
      }
    },
    computed: {
    },
    mounted() {
      this.topic(1)
    },
    methods: {
      // Фильтр по журналистам, каждый сюжет должен иметь одного журналиста
      staffJournalist(staffList) {
        var obj = staffList.filter(function (elem) {

          if (elem.assignment === 'journalist') {
            return elem.user.indexOf('') > -1;
          }
        })[0];
        if (obj) {
          return Object.values(obj)[1];
        }
      },

      isPageActive(page) {
        return this.paginationData.currentPage === page;
      },
      topic(page) {
        getTopic(page, this.searchString).then(response => {
          this.topics = response.data.data,
                  this.paginationData = pagination(response.data.pagination);
        })
      }
    }
  }
</script>

<style scoped>
  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>
