<template>
    <div class="main-page has-text-left">
        <div class="is-flex mt-6 is-align-items-center">
            <h1 class="title m-0">Монтажный лист - Главный о главном. Накипело. 17.01.2021</h1>
        </div>
        <div class="mt-5">
            <div class="columns is-desktop">
                <div v-if="!isEditing && !isTextEditing" class="column is-four-fifths">
                    <div class="mb-5">
                        <button v-on:click="setEditMode()" class="tag button is-small mr-1">Редактировать</button>
                        <button class="tag button is-small mr-1">Распечатать</button>
                        <button class="tag button is-small mr-1">Копировать выпуск</button>
                    </div>
                    <article class="message is-danger mb-1">
                        <div class="message-body black-text p-2">
                            <div class="color-block-head">
                                <span class="has-text-success mr-3">0:00</span><span>Студия (0:01)</span>
                            </div>
                            <div class="small-text-13">
                                Ирина Мигунова, Сергей Удовенко
                            </div>
                        </div>
                    </article>
                    <article class="message is-info mb-1">
                        <div class="message-body black-text p-2">
                            <div class="color-block-head">
                                <span class="has-text-success mr-3">0:00</span><span>Студия (0:01)</span>
                            </div>
                            <div class="small-text-13">
                                Ирина Мигунова, Сергей Удовенко
                            </div>
                        </div>
                    </article>
                    <article class="message is-success mb-1">
                        <div class="message-body black-text p-2">
                            <div class="color-block-head color-block-head--not-border">
                                <span class="has-text-success mr-3">0:00</span><span>Титры: Заголовок (0:00)</span>
                            </div>
                        </div>
                    </article>
                </div>
                <div v-if="isEditing" class="column is-four-fifths">
                    <div class="mb-2">
                        <button class="tag button is-small mr-1">В архив</button>
                        <button class="tag button is-small mr-1">Монтажный лист</button>
                        <button class="tag button is-small mr-1">На суфлер</button>
                        <button class="tag button is-small mr-1">Ведущие</button>
                        <button class="tag button is-small mr-1">Монтажеры</button>
                        <button class="tag button is-small mr-1">Копировать выпуск</button>
                        <button class="tag button is-danger is-small is-light mr-1">Удалить</button>
                        <button class="tag button is-small mr-1">Показать / скрыть</button>
                    </div>
                    <div class="mb-3">
                        <button class="tag button is-small is-success is-light mr-1">сюжет</button>
                        <button class="tag button is-small is-success is-light mr-1">заставка</button>
                        <button class="tag button is-small is-success is-light mr-1">титры</button>
                        <button class="tag button is-small is-success is-light mr-1">рекламный блок</button>
                        <button class="tag button is-small is-success is-light mr-1">студия</button>
                        <button class="tag button is-small is-success is-light mr-1">шпигель</button>
                        <button class="tag button is-small is-success is-light mr-1">другой выпуск</button>

                    </div>
                    <div class="mb-2">
                        <span class="small-text-13">Расчетное время: 30:39</span>
                    </div>
                    <div>
                        <article class="message is-small message-body--white mb-1">
                            <div class="message-header message-header--grey">
                                <p>
                                    <span class="has-text-success mr-3"><b>0:00</b></span><span><b>Студия:Студия ( 0:01 )</b></span>
                                </p>
                                <div>
                                    <button class="delete delete--drop is-small" aria-label="delete"></button>
                                    <button class="delete is-small ml-2" aria-label="delete"></button>
                                </div>

                            </div>
                            <div class="message-body">
                                <a href="">Редактировать</a>
                                <p> Ирина Мигунова, Сергей Удовенко
                                </p>

                            </div>
                        </article>
                        <article class="message is-small message-body--white mb-1">
                            <div class="message-header message-header--yellow">
                                <p>
                                    <span class="has-text-success mr-3"><b>0:00</b></span><span><b>Студия:Студия ( 0:01 )</b></span>
                                </p>
                                <div>
                                    <button class="delete delete--drop is-small" aria-label="delete"></button>

                                    <button class="delete is-small ml-2" aria-label="delete"></button>
                                </div>

                            </div>
                            <div class="message-body">
                                <a href="">Редактировать</a>
                                <p> Ирина Мигунова, Сергей Удовенко
                                </p>

                            </div>
                        </article>
                        <article class="message is-small message-body--white mb-1">
                            <div class="message-header message-header--green">
                                <p>
                                    <span class="has-text-success mr-3"><b>0:00</b></span><span><b>Студия:Студия ( 0:01 )</b></span>
                                </p>
                                <div>
                                    <button class="delete delete--drop is-small" aria-label="delete"></button>

                                    <button class="delete is-small ml-2" aria-label="delete"></button>
                                </div>

                            </div>
                            <div class="message-body">
                                <a href="">Редактировать</a>
                                <p> Ирина Мигунова, Сергей Удовенко
                                </p>

                            </div>
                        </article>
                        <article class="message is-small message-body--white mb-1">
                            <div class="message-header message-header--blue">
                                <p>
                                    <span class="has-text-success mr-3"><b>0:00</b></span><span><b>Студия:Студия ( 0:01 )</b></span>
                                </p>
                                <div>
                                    <button class="delete delete--drop is-small" aria-label="delete"></button>

                                    <button class="delete is-small ml-2" aria-label="delete"></button>
                                </div>

                            </div>
                            <div class="message-body">
                                <a href="">Редактировать</a>
                                <p> Ирина Мигунова, Сергей Удовенко
                                </p>

                            </div>
                        </article>
                    </div>
                </div>

                <div class="column">
                    <div class="user-info-block small-text-13">
                        <div class="user-info-block-item"><span>Редактор:	</span><span>Селютин Илья</span></div>
                        <div class="user-info-block-item"><span>Дата:		</span><span>1 января 2022</span></div>
                        <div class="user-info-block-item"><span>Общее время:	</span><span>30:39</span></div>
                        <div class="user-info-block-item"><span>Файлы:	</span><span> - </span></div>
                        <div class="user-info-block-item"><a href="">Редактировать</a></div>
                    </div>
                </div>
            </div>


        </div>
    </div>

</template>

<script>
    export default {
        name: "Issue",
        data() {
            return {
                isEditing: false,
                isTextEditing: false,
            }
        },
        methods: {
            setEditMode() {
                this.isEditing = !this.isEditing;
            },
            setTextEditMode() {
                this.isTextEditing = !this.isTextEditing;
            },
        },
    }
</script>

<style scoped>

</style>
