<template>
    <div class="main-page">
        <div class="is-flex is-align-items-center">
            <h1 class="title m-0">Личный кабинет</h1>
        </div>

        <ul class="tabs__list mt-5">
            <li class="tabs__item">
                <button class="tabs__link tabs__link--report" v-on:click="setTabsType('statuses', 'statuses')"
                        v-bind:class="{ 'tabs__link--active': statusesClassActive }">
                    Сюжеты и графики
                </button>
            </li>
            <li class="tabs__item">
                <button class="tabs__link tabs__link--report" v-on:click="setTabsType('userinfo', 'userinfo')"
                        v-bind:class="{ 'tabs__link--active': userInfoClassActive }">
                    Личная информация
                </button>
            </li>
        </ul>
<!--        <h2 class="subtitle mt-5">Сюжеты и графики</h2>-->

        <div class="is-flex mt-5" v-if="tabType === 'statuses'">
            <article class="panel is-danger mr-2 h100">
                <p class="panel-heading small-text-13">
                    Пишется
                </p>
                <ul>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">
                        Зайцева Наталья
                    </a>
                        <ul>
                            <li class="small-text-13 is-italic ml-5"><a class="panel-block is-active">Губернатор и
                                застройщики</a></li>
                            <li class="small-text-13 is-italic ml-5"><a class="panel-block is-active">урок физики в Борисовке "точка роста"</a></li>
                            <li class="small-text-13 is-italic ml-5"><a class="panel-block is-active">Волейбол. спартакиада студентов</a></li>
                            <li class="small-text-13 is-italic ml-5"><a class="panel-block is-active">Первая тренировка спидвея</a></li>
                            <li class="small-text-13 is-italic ml-5"><a class="panel-block is-active">Ярмарка детей предпринимателей</a></li>
                            <li class="small-text-13 is-italic ml-5"><a class="panel-block is-active">МВД мошенницы и старушки</a></li>
                        </ul>
                    </li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">Калинина Виктория</a></li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">Клыга Анна</a></li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">Марина Роман</a></li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">Муравьева Ольга</a></li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">Новикова Вера</a></li>
                </ul>


            </article>
            <article class="panel is-warning h100 mr-2">
                <p class="panel-heading small-text-13">
                    На проверке
                </p>
                <ul>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">
                        Кулькова Наталья
                    </a>
                        <ul>
                            <li class="small-text-13 is-italic ml-5"><a class="panel-block is-active">20.12 Ярмарка в ЦНТ в Новости</a></li>
                        </ul>
                    </li>
                </ul>
            </article>
            <article class="panel is-success h100 mr-2">
                <p class="panel-heading small-text-13">
                    В производстве
                </p>
                <ul>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">
                        Антонов Игорь
                    </a>
                        <ul>
                            <li class="small-text-13 is-italic ml-5"><a class="panel-block is-active">Новый салон Мебельград</a></li>
                        </ul>
                    </li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">
                        Марина Роман
                    </a></li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">
                    Новикова Вера
                </a></li>
                </ul>
            </article>
            <article class="panel is-info mr-2 h100">
                <p class="panel-heading small-text-13">
                    В эфире
                </p>
                <ul>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">
                        Владислав Нам
                    </a>
                        <ul>
                            <li class="small-text-13 is-italic ml-5"><a class="panel-block is-active">Кадриль на свежем воздухе</a></li>
                        </ul>
                    </li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">Зайцева Наталья</a></li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">Калинина Виктория</a></li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">Клыга Анна</a></li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">Кулькова Наталья</a></li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">Марина Роман</a></li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">Муравьева Ольга</a></li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">Новикова Вера</a></li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">Шаврина Юлия</a></li>
                </ul>


            </article>
            <article class="panel is-primary mr-2 h100">
                <p class="panel-heading small-text-13">
                    График монтажа
                </p>
                <ul>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">
                        21 декабря (Вт)
                    </a>
                        <ul>
                            <li class="small-text-13 is-italic ml-5"><a class="panel-block is-active">15:00 Дмитрий Малашкин</a></li>
                        </ul>
                    </li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">22 декабря (Ср)</a></li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">23 декабря (Чт)</a></li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">24 декабря (Пт)</a></li>
                </ul>


            </article>
            <article class="panel is-primary mr-2 h100">
                <p class="panel-heading small-text-13">
                    График съемок
                </p>
                <ul>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">
                        22 декабря (Ср)
                    </a>
                        <ul>
                            <li class="small-text-13 is-italic ml-5"><a class="panel-block is-active">15:45 Адонин Роман</a></li>
                        </ul>
                    </li>
                    <li><a class="small-text-13 panel-block is-active has-text-weight-bold">28 декабря (Вт)</a></li>
                </ul>


            </article>
            <article class="panel is-primary mr-2 h100">
                <p class="panel-heading small-text-13">
                    График машин
                </p>
                <ul>
                    <li> -
                    </li>
                </ul>


            </article>

        </div>
        <div v-if="tabType === 'userinfo'">
            <div class="profile__item-container mt-5">
                <div class="profile__data-list">
                    <div class="profile__data-item">
                        <div class="profile__data-col">
                            <span class="profile__label">Имя, Фамилия: </span>
                            <span class="profile__data">Милорд Сеньер</span>
                        </div>
                    </div>
                    <div class="profile__data-item">
                        <div class="profile__data-col">
                            <span class="profile__label">E-mail: </span>
                            <span class="profile__data">username@mail.ru</span>
                        </div>
                    </div>
                    <div class="profile__data-item">
                        <div class="profile__data-col">
                            <span class="profile__label">Телефон: </span>
                            <span class="profile__data">+7 (914) 123-45-67</span>
                        </div>
                    </div>
                    <div class="profile__data-item">
                        <div class="profile__data-col profile__data-col--bottom">
                            <router-link class="button button__link profile__button profile__button--mb" to="/profile/change">Изменить личные данные</router-link>
                            <router-link class="button button__link profile__button" to="/profile/pass_change">Изменить пароль</router-link>
                        </div>
                    </div>


                    <div></div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Cabinet",
        data() {
            return {
                tabType: 'statuses',
                statusesClassActive: true,
                userInfoClassActive: false,
            }
        },
        methods: {
            setTabsType(type, active) {
                this.tabType = type;
                if (active === 'userinfo') {
                    this.userInfoClassActive = true;
                    this.statusesClassActive = false;
                }
                else {
                    this.userInfoClassActive = false;
                    this.statusesClassActive = true;
                }
            }
        },

    }
</script>

<style scoped>

</style>
