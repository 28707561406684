import {createApp} from 'vue'
import App from './App.vue'
import store from "@/store";
import './assets/sass/style.scss'
import router from './router'
// import './../node_modules/bulma/css/bulma.css

const app = createApp(App)

app.use(router).use(store).mount('#app')

export function makeDurationInt(data) {
    let durationArray = data.split(':');
    return durationArray[0] * 60 + durationArray[1];
}

export function makeDurationStringTime(data) {
    let min = data.toString().slice(0, -2) / 60;
    return min + ':' + data % 60;
}


app.config.globalProperties.$parseText = text => {

    const regex = /\d:\d+/g;
    let testRe = text.replaceAll(regex, 'time');
    console.log('regexp: ' + testRe);

    let finalText = text.replaceAll('(( LIFE:', '<p><FONT COLOR="#8d1700">Лайф <p>').replaceAll('))', '</FONT COLOR></p></p>');
    return { text: finalText}
}

// app.config.globalProperties.$parseText = text => {
//     let reTimeStart = /(\d:\d+)/g;
//     let reTimeEnd = /(- )(\d:\d+)/g;
//     let timeStart = reTimeStart.exec(text);
//     let timeEnd = reTimeEnd.exec(text);
//     let durationStart = makeDurationInt(timeStart[1]);
//     let durationEnd = makeDurationInt(timeEnd[2]);
//
//     let durationFinal = makeDurationStringTime(durationEnd - durationStart);
//
//     console.log('Duration start: ' + durationStart);
//     console.log('Duration end: ' + durationEnd);
//     console.log('Duration final: ' + durationFinal);
//
//
//     let finalText = text.replaceAll('(( LIFE:', '<p><FONT COLOR="#8d1700">Лайф <p>').replaceAll('))', '</FONT COLOR></p></p>');
//     return { text: finalText}
// }